
import './App.css';
import React from 'react';
import { Link } from 'react-router-dom';
function AI() {
return(
<div style={{ backgroundColor: 'black' }}>
<p>&nbsp;&nbsp;&nbsp;</p>
<Link to="/" style={{ backgroundColor: 'black', flex: '1', alignItems:'center', color:'white'}}>Return to Home Page</Link>
</div>
);
}
export default AI;