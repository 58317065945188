import './App.css';
import React from 'react';
//import ELH from './ThePhysicsOfMorals.pdf';
import photo from './LewieHermanProfPic.png';
import { Link } from 'react-router-dom';

function Home() {
  const { name, bio, patreonLink, venmo } = {
    name: 'Lewis Herman',
    bio: 'I am an ethical philosopher from Evanston, IL. I wrote a book on ethics called "The Physics of Morals". If you like my work, consider donating on Venmo @lewie-herman or joining my Patreon.',
    patreonLink: 'https://www.patreon.com/LewisHerman',
    venmo: "https://www.venmo.com/Lewie-Herman"
  };

  return (
    <div style={{ backgroundColor: 'black' }}>
      <div style={{ backgroundColor: 'black', color: 'white', padding: '20px', height: '200%'}}>
        <img src={photo} alt={name} style={{ width: '200px', height: '200px' }} />
        <h1>{name}</h1>
        <p>{bio}</p>
        <a
          style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}
          href={patreonLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Support me on Patreon
        </a>
        <a
          style={{ backgroundColor: 'black', color: 'White', padding: '20px' }}
          href={venmo}
        >
          Support me on Venmo: Lewie-Herman
        </a>
        <br />
        <h1>Books I've Written</h1>
        <a href={'https://www.amazon.com/dp/B0C87DBCW8'}>The Physics of Morals</a>
        <br />
        <a style={{ color: 'white' }} href={'https://www.amazon.com/dp/B0C7F3GZCX/ref=sr_1_1?crid=15F7C4VD1FB86&keywords=soul%20worlds%20lewis%20herman&qid=1686241251&rnid=2941120011&s=books&sprefix=soul%20worlds%20lewis%20herman%2Caps%2C92&sr=1-1&fbclid=IwAR0qB5g8rJc26QJOOYHOQcxXkffMC63Z4-d-0f-u9xQLocpUJhQMz0Tj1IQ'}>
          Soul Worlds
        </a>
        <br />
        <a>The Story Book (Coming soon)</a>
        <br />
        <Link to="/ELHAI" style={{display:'flex', alignItems:'center'}}>Click Here for an AI trained on The Physics of Morals</Link>
        <Link to="https://chat.openai.com/g/g-hZSNeM0kd-elh-ai?fbclid=IwAR19f1bOWzvvOWzcPmk_FXUxPGuxHBF7ORQT96lYAhn-2COqnDKuHlPk1rw"> Here is the ELH AI </Link>
      </div>
    </div>
  );
}

export default Home;