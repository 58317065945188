import React from 'react';
//Home
import Home from './components/Home';
import AI from './components/AI'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
  return (
    <Router>
      <Routes>
      <Route exact path="/" element={<Home/>}/>
      <Route path="/ELHAI" element={<AI/>}/>
      </Routes>
    </Router>
  );
}

export default App;